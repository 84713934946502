import React, {useEffect, useState} from "react";
// import BannerImg from "../../images/hero-banner.png";
// import LiveOne from "../../images/liveone.png";
// import LiveTwo from "../../images/livetwo.png";
// import LiveThree from "../../images/livethree.png";
import "./styles.scss";
// import {useNavigate} from "react-router-dom";

const LandingPage = () => {
//   const currentURL = window.location.href;
//   const navigate = useNavigate();
  useEffect(() => {
      window.location.replace("https://sellers.kiko.live/");
  }, []);
  return <></>;

  //   return (
  //     <>
  //       {/* <div className="navbar-layout-white inited" id="nav-wrapper">
  //         <nav className="navbar affix-top" data-spy="affix" data-offset-top="0">
  //           <div className="container">
  //             <div className="navbar-logo ">
  //               <a
  //                 target="_blank"
  //                 href="https://sellers.kiko.live/"
  //                 className="logo"
  //                 rel="noreferrer"
  //               >
  //                 <img
  //                   src="//kiko.link/wp-content/uploads/2023/02/cropped-untitled-design.png"
  //                   alt="kiko"
  //                 />
  //               </a>
  //             </div>
  //             <div className="registerBtn">
  //               <a
  //                 target="_blank"
  //                 href="https://sellers.kiko.live/"
  //                 className="regibtn"
  //                 rel="noreferrer"
  //               >
  //                 Register your interest
  //               </a>
  //             </div>
  //           </div>
  //         </nav>
  //       </div>
  //       <section className="hero-section">
  //         <div className="container">
  //           <div className="row">
  //             <div className="col-lg-12 col-sm-12">
  //               <div className="hero-flex">
  //                 <div className="hero-text">
  //                   <h1>
  //                     Start selling your products on <span>ONDC</span> with{" "}
  //                     <span>KIKO LIVE</span>
  //                   </h1>
  //                   <h6>Become a seller and grow your business in India</h6>
  //                   <a
  //                     target="_blank"
  //                     href="https://sellers.kiko.live/"
  //                     className="anchor-point"
  //                     style={{textDecoration: "none"}}
  //                     rel="noreferrer"
  //                   >
  //                     REGISTER HERE TO SELL ON ONDC
  //                   </a>
  //                 </div>
  //                 <div className="hero-img">
  //                   <img src={BannerImg} />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </section>
  //       <section className="step-section">
  //         <h2>Get started in 5 simple steps</h2>
  //         <div className="step-bg">
  //           <div className="container">
  //             <div className="row">
  //               <div className="col-lg-12">
  //                 <ul className="step-list">
  //                   <li>
  //                     <span>Step 1</span>
  //                     <h4>Create your account</h4>
  //                     <p>All you need is: Address, Adhaar & Bank Account</p>
  //                   </li>
  //                   <li>
  //                     <span>Step 2</span>
  //                     <h4>List your products</h4>
  //                     <p>Upload and list the products you want to sell</p>
  //                   </li>
  //                   <li>
  //                     <span>Step 3</span>
  //                     <h4>Get Orders</h4>
  //                     <p>Start getting orders from customers across India</p>
  //                   </li>
  //                   <li>
  //                     <span>Step 4</span>
  //                     <h4>Order Fulfilment</h4>
  //                     <p>
  //                       Optimize logistics by choosing on or off network
  //                       fulfilment partners
  //                     </p>
  //                   </li>
  //                   <li>
  //                     <span>Step 5</span>
  //                     <h4>Get your payments</h4>
  //                     <p>
  //                       Get your payments into your account regularly ( after the
  //                       stipulated order delivery+ return days cycle)
  //                     </p>
  //                   </li>
  //                 </ul>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </section>
  //       <section className="live-section">
  //         <h2>Why your business should be on KIKO LIVE</h2>
  //         <p>
  //           Designed for the unique needs of merchants across India. Selling
  //           online was never this easy and quick
  //         </p>
  //         <div className="container">
  //           <div className="row">
  //             <div className="col-lg-12 col-sm-12">
  //               <ul className="live-list">
  //                 <li>
  //                   <img src={LiveOne} />
  //                   <h4>Zero Subscription Cost</h4>
  //                   <p>
  //                     No upfront payment to start selling online. Pay-as-you-go
  //                     model where you pay only a nominal transaction fee per
  //                     order.
  //                   </p>
  //                 </li>
  //                 <li>
  //                   <img src={LiveTwo} />
  //                   <h4>Connected With ONDC</h4>
  //                   <p>
  //                     Register your presence on the huge ONDC network that makes
  //                     your products discoverable by customers all over India.
  //                   </p>
  //                 </li>
  //                 <li>
  //                   <img src={LiveThree} />
  //                   <h4>Own Seller Page</h4>
  //                   <p>
  //                     Sell products from your dedicated seller page to get
  //                     undivided customer attention. Make it easy for your loyal
  //                     customers to buy from you with a unique QR code for your
  //                     business.
  //                   </p>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </section> */}
  //     </>
  //   );
};
export default LandingPage;
